import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-gtag';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Outdoor_Cameras/IN-7011_HD/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "INSTAR IN-7011 HD Produkt Features",
  "path": "/Outdoor_Cameras/IN-7011_HD/Product_Features/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "Die IN-7011 HD ist der Nachfolger der IN-4011 Pan & Tilt Außenkamera. Überwachen Sie einen weiten Bereich, indem Sie den Kamerakopf mit unserer App steuern.",
  "image": "./P_SearchThumb_IN-7011HD_Features.png",
  "social": "/images/Search/P_SearchThumb_IN-7011HD_Features.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-IN-7011HD_white.webp",
  "chapter": "Outdoor Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='INSTAR IN-7011 HD Produkt Features' dateChanged='2017-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='Die IN-7011 HD ist der Nachfolger der IN-4011 Pan & Tilt Außenkamera. Überwachen Sie einen weiten Bereich, indem Sie den Kamerakopf mit unserer App steuern.' image='/images/Search/P_SearchThumb_IN-7011HD_Features.png' twitter='/images/Search/P_SearchThumb_IN-7011HD_Features.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Outdoor_Cameras/IN-7011_HD/Product_Features/' locationFR='/fr/Outdoor_Cameras/IN-7011_HD/Product_Features/' crumbLabel="Features" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "in-7011-full-hd-manual",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#in-7011-full-hd-manual",
        "aria-label": "in 7011 full hd manual permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-7011 Full HD Manual`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/92e08a1bfcf1176787baa3e5eb1918ef/573d3/IN-7011_Overview_width1650.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "51.30434782608696%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABvklEQVQoz32SyU4CQRCG5wlMfA+96Ml44KAXX8DEk4m8A1eXcPBJNEFciIpE45qgLGLGPYLAbKKCM4yADsz2m+6xcYzLn3RSU1399T9dxbmuC7KIDMNArfZCY3/etu1uTOQ4DhTlgeaZ2C7HDhNpWh35/P1X0T9ASVYgyw8QBBGvjQZkRUahUATHnJBVqTxBECW8vxu/Atl3s9midZIk4/HxCaenZ7i6ukEqnfWARLqu4+b2DpqmQ1Eq8IsAiSsGK5UEPD9XqQFyuaqqyGZzuLi89oCW7eC+WMJdvoB0OgNJUlCv678CRVGCKClotd6gqhrKZZHuJxLb2N7Z897wtdFE8jhNHSaTJygWy9SF/5cZsCyIiESi2N09RCwWRy7H0zzPnyOVynhA0t31jTgymRwFEej5xeUPh+yC4FQQPT29GB0ZQ6368u15OKNj0mBzK4H+/j4MDA4gEAigWq11G+N3aJomeJ5HKBTCwsIi7M+87bi0hutYFmwXMB0X0zMzGB4eQnQ52h0P5tCyvJk72D/C3GwYkcgSwuF5jE9MIrq6BtOxf86hX38NNnFIOk3UbrexvBLD4XESb502BX4AlTHhCHtITFEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/92e08a1bfcf1176787baa3e5eb1918ef/e4706/IN-7011_Overview_width1650.avif 230w", "/en/static/92e08a1bfcf1176787baa3e5eb1918ef/d1af7/IN-7011_Overview_width1650.avif 460w", "/en/static/92e08a1bfcf1176787baa3e5eb1918ef/7f308/IN-7011_Overview_width1650.avif 920w", "/en/static/92e08a1bfcf1176787baa3e5eb1918ef/e1c99/IN-7011_Overview_width1650.avif 1380w", "/en/static/92e08a1bfcf1176787baa3e5eb1918ef/e02f7/IN-7011_Overview_width1650.avif 1650w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/92e08a1bfcf1176787baa3e5eb1918ef/a0b58/IN-7011_Overview_width1650.webp 230w", "/en/static/92e08a1bfcf1176787baa3e5eb1918ef/bc10c/IN-7011_Overview_width1650.webp 460w", "/en/static/92e08a1bfcf1176787baa3e5eb1918ef/966d8/IN-7011_Overview_width1650.webp 920w", "/en/static/92e08a1bfcf1176787baa3e5eb1918ef/445df/IN-7011_Overview_width1650.webp 1380w", "/en/static/92e08a1bfcf1176787baa3e5eb1918ef/44758/IN-7011_Overview_width1650.webp 1650w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/92e08a1bfcf1176787baa3e5eb1918ef/81c8e/IN-7011_Overview_width1650.png 230w", "/en/static/92e08a1bfcf1176787baa3e5eb1918ef/08a84/IN-7011_Overview_width1650.png 460w", "/en/static/92e08a1bfcf1176787baa3e5eb1918ef/c0255/IN-7011_Overview_width1650.png 920w", "/en/static/92e08a1bfcf1176787baa3e5eb1918ef/b1001/IN-7011_Overview_width1650.png 1380w", "/en/static/92e08a1bfcf1176787baa3e5eb1918ef/573d3/IN-7011_Overview_width1650.png 1650w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/92e08a1bfcf1176787baa3e5eb1918ef/c0255/IN-7011_Overview_width1650.png",
              "alt": "INSTAR IN-7011 HD",
              "title": "INSTAR IN-7011 HD",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`The IN-7011 HD is a weather proof dome camera which can either be used in in- or outdoor environments. A convenient motor allows for quick readjusting of the lenses viewing direction and can be controlled by any network device such as a tablet or a smartphone. The built-in infrared diodes allow you to see in pitch dark environments, while the integrated IR cut filter aids in the display of vibrant colours, as seen by the human eye. Through the use of an efficient h.264 compression codec it is able of recording video files on a MicroSD memory card with comparably small size but best picture results.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      